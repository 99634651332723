import service from "../config.js";
import link from "../link.js";

let ApiUrl =link.IOtApiHong.APIService;

//水电表统计
export const GetWaterElectricityList = async (StartTime,EndTime,ParamNum) => {
    let responseData = await service({
      url: ApiUrl + "/api/WaterElectricity/GetWaterElectricityData",
      method: "GET",
      params: {
        StartTime:StartTime,
        EndTime:EndTime,
        ParamNum:ParamNum
      }
    })
    return responseData;
  }