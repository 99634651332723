<template>
  <div class="hydropower">
    <div class="h-header">
      <van-icon @click="$router.go(-1)" name="arrow-left" />
      <span>水电统计图</span>
    </div>
    <div class="h-date">
      <div :class="{ active: state === 0 }" @click="toggleData(0)">日</div>
      <div :class="{ active: state === 1 }" @click="toggleData(1)">周</div>
      <div :class="{ active: state === 2 }" @click="toggleData(2)">年</div>
      <div :class="{ active: state === 3 }" @click="toggleData(3)">
        <span>{{ calendarTitle1 }}</span>
        <span>至</span>
        <span> {{ calendarTitle2 }}</span>
      </div>
    </div>
    <div class="h-details">
      <div>
        <div>
          <span>{{ElectricityType}}</span>
          <span>kW·h</span>
        </div>
        <div>
          <i class="iconfont icon-shuidian-dian"></i>
        </div>
        <div>{{ElectricityTypeName}}</div>
      </div>
      <div>
        <div>
          <span>{{WaterData}}</span>
          <span>m³</span>
        </div>
        <div>
          <i class="iconfont icon-shuidian-shui"></i>
        </div>
        <div>{{WaterDataName}}</div>
      </div>
    </div>
    <div id="lineCharts1" class="electricity-consumption"></div>
    <div id="lineCharts2" class="water-consumption"></div>
    <van-calendar v-model="showCalendar" type="range" :min-date="minDate" :color="defineStyle.themeColor" :title="title" :show-mark="false" @confirm="onConfirmCalendar" :max-range="31" range-prompt="选择天数不能超过一个月" />
  </div>
</template>
<script>
import { GetWaterElectricityList } from "@/api/Hydropower/HydropowerStatistical"
import * as echarts from "echarts";
import { Calendar } from "vant";

// require("echarts/theme/macarons"); //引入主题
export default {
  components: {
    [Calendar.name]: Calendar,
  },
  data () {
    return {
      state: 0,
      title: "",
      calendarTitle1: "01/12周二",
      calendarTitle2: "01/14周四",
      lineCharts1: null,
      lineCharts2: null,
      lineCharts1ArrList: [],
      lineCharts2ArrList: [],
      ElectricityType: null, //电总用量
      WaterData: null, //水总用量
      ElectricityTypeName: null,//电标题
      WaterDataName: null, //水标题
      showCalendar: false,
      minDate: this.dayjs().subtract(10, "year").$d.$d,
      month: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      week: ["周一", "周二", "周三", "周四", "周五", "周六", "周天"],
      date: [],

    };
  },
  methods: {
    toggleData (num) {
      if (parseInt(num) != 3) {
        this.state = parseInt(num);
        switch (this.state) {
          case 0:
            let StartTime = this.dayjs(this.dayjs(this.dayjs().toDate()).format("YYYY-MM-DD")).unix();
            let EndTime = this.dayjs(this.dayjs(this.dayjs(this.dayjs().toDate()).format("YYYY-MM-DD").toString()).add(1, "day").format("YYYY-MM-DD")).unix();
            this.CommonAPiData(StartTime, EndTime, 1);
            this.ElectricityTypeName = "本日用电量";
            this.WaterDataName = "本日用水量"
            break;
          case 1:
            let StartTime1 = this.dayjs(this.dayjs().day(1).format("YYYY-MM-DD")).unix();
            let EndTime1 = this.dayjs(this.dayjs(this.dayjs().day(6).format("YYYY-MM-DD").toString()).add(2, "day").format("YYYY-MM-DD")).unix()
            this.CommonAPiData(StartTime1, EndTime1, 2);
            this.ElectricityTypeName = "本周用电量";
            this.WaterDataName = "本周用水量"
            break;
          case 2:
            let StartTime2 = this.dayjs(this.dayjs().startOf('year').format("YYYY-MM-DD")).unix();
            let EndTime2 = this.dayjs(this.dayjs(this.dayjs().endOf('year').format("YYYY-MM-DD").toString()).add(1, "day").format("YYYY-MM-DD")).unix()
            this.CommonAPiData(StartTime2, EndTime2, 3);
            this.ElectricityTypeName = "本年用电量";
            this.WaterDataName = "本年用水量"
            break;
          default:
            break;
        }
      } else {
        this.showCalendar = true;
      }
    },

    onConfirmCalendar (date) {
      this.calendarTitle1 =
        this.dayjs(date[0]).format("MM") +
        "/" +
        this.dayjs(date[0]).format("DD");
      this.calendarTitle2 =
        this.dayjs(date[1]).format("MM") +
        "/" +
        this.dayjs(date[1]).format("DD");
      // 隐藏日期框，赋值日期数组，切换日期
      this.showCalendar = false;
      this.date = date;
      this.state = 3;
      console.log(this.dayjs(date[0]).format("YYYY") + "-" + this.dayjs(date[0]).format("MM") + "-" + this.dayjs(date[0]).format("DD"));
      var modelData = {
        startTime: this.dayjs(date[0]).format("YYYY") + "-" + this.dayjs(date[0]).format("MM") + "-" + this.dayjs(date[0]).format("DD"),
        EndTime: this.dayjs(date[1]).format("YYYY") + "-" + this.dayjs(date[1]).format("MM") + "-" + this.dayjs(date[1]).format("DD")
      }
      this.ElectricityTypeName = this.calendarTitle1 + "至" + this.calendarTitle2 + "用电量";
      this.WaterDataName = this.calendarTitle1 + "至" + this.calendarTitle2 + "用水量";
      this.CommonAPiData(this.dayjs(modelData.startTime).unix(), this.dayjs(modelData.EndTime).unix(), 4);
      console.info(modelData, "1");

      // this.defineEConsumption("用电量", this.lineCharts1, "lineCharts1", [
      //   1,
      //   2,
      //   3,
      //   3,
      //   5,
      //   6,
      //   5,
      //   3,
      //   6,
      //   5,
      //   5,
      //   4,
      // ]);
      // this.defineEConsumption("用水量", this.lineCharts2, "lineCharts2", [
      //   1,
      //   2,
      //   3,
      //   3,
      //   5,
      //   6,
      //   9,
      //   3,
      //   6,
      //   5,
      //   5,
      //   4,
      // ]);

    },
    // 计算自定义日期
    calcRange (date) {
      let day = [31, 28, 31, 30, 31, 30, 30, 31, 30, 31, 30, 31];
      let arr = [];
      let startTime = parseInt(this.dayjs(date[0]).format("MM"));
      let startDay = parseInt(this.dayjs(date[0]).format("DD"));
      let endTime = parseInt(this.dayjs(date[1]).format("MM"));
      let endDay = parseInt(this.dayjs(date[1]).format("DD"));

      if (startTime === "02" || endTime === "02") {
        if (this.dayjs(date[0]).isLeapYear()) {
          day[1] = 29;
        }
      }
      if (endTime - startTime != 0) {
        for (
          let index = parseInt(startDay);
          index <= day[startTime - 1];
          index++
        ) {
          arr.push(index);
        }
        for (let a = 1; a <= parseInt(endDay); a++) {
          arr.push(a);
        }
      } else {
        for (
          let index = parseInt(startDay);
          index <= parseInt(endDay);
          index++
        ) {
          arr.push(index);
        }
      }
      return arr;
    },
    // 计算x轴和日期并初始化折线图
    defineEConsumption (title, windowObj, obj, data) {

      if (this.lineCharts1 || this.lineCharts2) {
        this.lineCharts1.dispose();
        this.lineCharts2.dispose();
      }
      let xAxis = [];

      switch (this.state) {
        case 0:
          for (let index = 0; index < 24; index++) {
            xAxis.push(index);
          }
          break;
        case 1:
          for (let index = 0; index < 7; index++) {
            xAxis.push(this.week[index]);
          }
          break;
        case 2:
          for (let index = 0; index < 12; index++) {
            xAxis.push(this.month[index]);
          }
          break;
        case 3:
          xAxis = this.calcRange(this.date);
          break;
      }
      //alert(this.dayjs.extend(weekOfYear))
      //console.log(this.dayjs().get('day'))
      //alert(this.dayjs().weekday())
      //console.info(xAxis);
      windowObj = echarts.init(
        document.getElementById(obj)
        // "macarons"
      );

      windowObj.setOption({
        grid: {
          width: "85%",
        },
        title: {
          text: title,
          top: 20,
          left: 20,
          textStyle: {
            fontSize: 16,
            color: this.defineStyle.titleColor,
          },
        },
        tooltip: {
          trigger: "axis",
        },

        xAxis: {
          type: "category",
          boundaryGap: false, //坐标轴两边留白
          data: xAxis,
          axisTick: {
            show: false,
          },

          axisLine: {
            show: false,
          },
          axisLabel: {
            interval: this.state === 2 || this.state === 1 ? 0 : 1, // 坐标轴刻度标签的显示间隔
            rotate: this.state === 2 ? 40 : 0,
            textStyle: {
              color: this.defineStyle.subtitleColor,
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: this.defineStyle.subtitleColor,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: this.defineStyle.color.gray,
                opacity: 0.5,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: this.dayjs().year(),
            type: "line",
            symbol: "none",
            smooth: true,
            scale: false,
            itemStyle: {
              color: this.defineStyle.themeColor,
            },
            lineStyle: {
              color: this.defineStyle.themeColor,
              width: 1,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0.1,
                  color: "rgba(253, 146, 50, 0)",
                },
                {
                  offset: 1,
                  color: "rgba(253, 146, 50, 1)",
                },
              ]),
            },
            data: data,
          },
        ],
      });
    },
    async CommonAPiData (StartTime, EndTime, ParamNum) {
      //console.info(models,"2");
      let Result = await GetWaterElectricityList(StartTime, EndTime, ParamNum);
      if (Result.ResultCode == 200) {
        console.info(Result.Data);
        this.ElectricityType = Result.Data.Item1[0].ElectricityType;
        this.WaterData = Result.Data.Item1[0].WaterData;
        this.lineCharts1ArrList = Result.Data.Item2;
        this.lineCharts2ArrList = Result.Data.Item3;
        this.defineEConsumption("用电量", this.lineCharts1, "lineCharts1", this.lineCharts1ArrList);
        this.defineEConsumption("用水量", this.lineCharts2, "lineCharts2", this.lineCharts2ArrList);
      }
      else if (Result.ResultCode == 206) {

      } else {
        alert("API接口请求错误");
      }
    },
  },
  computed: {},
  mounted () {
    let StartTime = this.dayjs(this.dayjs(this.dayjs().toDate()).format("YYYY-MM-DD")).unix();
    let EndTime = this.dayjs(this.dayjs(this.dayjs(this.dayjs().toDate()).format("YYYY-MM-DD").toString()).add(1, "day").format("YYYY-MM-DD")).unix();
    this.CommonAPiData(StartTime, EndTime, 1);
    this.ElectricityTypeName = "本日用电量";
    this.WaterDataName = "本日用水量"
  },
};
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.hydropower {
	width: 100%;
	height: 100%;
	background: #fff;
	overflow-y: scroll;
	.h-header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		height: 58px;
		color: $titleColor;
		i {
			font-size: 22px;
			margin-left: 16px;
			padding-top: 4px;
		}
		span {
			margin-left: 10px;
			font-size: 22px;
			font-weight: 400;
		}
	}
	.h-date {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin-left: 16px;
		margin-right: 16px;
		margin-top: 10px;
		> div {
			padding: 7px 16px;
			box-shadow: 0px 0px 5px #ebebeb;
			border-radius: 17px;
			font-size: 14px;
			font-weight: 400;
			color: $titleColor;
		}
		> div:not(:last-child) {
			margin-right: 8px;
		}
		> div.active {
			background-color: $themeColor;
			color: #fff;
			> span {
				color: #fff !important;
			}
		}
		> div:last-child {
			font-size: 12px;
			> span:nth-child(2) {
				color: #949494;
				margin: 0 6px;
			}
		}
	}
	.h-details {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		margin-top: 24px;
		> div {
			width: 164px;
			height: 98px;
			background: #ffffff;
			box-shadow: 0px 0px 8px rgba(200, 200, 200, 0.5);
			opacity: 1;
			border-radius: 8px;
			padding: 12px;
			position: relative;
			> div:first-child {
				> span:first-child {
					font-size: 30px;
					font-weight: 600;
					color: $titleColor;
					margin-right: 2px;
				}
				> span:last-child {
					font-size: 12px;
					font-weight: 400;
					color: $defocus;
				}
			}
			> div:nth-child(2) {
				position: absolute;
				top: 5px;
				right: 3px;
				> i {
					font-size: 68px;
					color: $themeColor;
					opacity: 0.1;
				}
			}
			> div:last-child {
				margin-top: 20px;

				font-size: 14px;
				font-weight: 400;
				color: #a0a0a0;
			}
		}
		> div:first-child {
			margin-right: 15px;
		}
	}
	.electricity-consumption {
		width: 100%;
		height: 234px;
		margin-bottom: 20px;
	}
	.water-consumption {
		width: 100%;
		height: 234px;
	}
}
</style>